import { Dispatch } from 'react'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import { usePostShareVendorStatusMutation } from 'src/graphql/generated/hooks'
import { showToast } from 'src/store/actions/toastAction'

interface Props {
  vendorName: string | null
  isOpen: boolean
  onClose: () => void
}

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '0px',
    transform: 'translate(-50%, -50%)',
    borderWidth: '0px',
    backgroundColor: 'transparent',
    border: '0px solid red',
  },
}

export const VendorState: React.FC<Props> = ({ isOpen, onClose, vendorName }) => {
  const [postshareVendorStatus] = usePostShareVendorStatusMutation()
  const dispatch: Dispatch<any> = useDispatch()
  const handleClose = () => {
    onClose()
  }
  const handleContactUs = async () => {
    try {
      const res = await postshareVendorStatus({
        variables: {
          data: {
            name: vendorName,
          },
        },
      })
      if (res) {
        dispatch(showToast('Email sent to LTH successfully.', 'success'))
      }
    } catch (error: any) {
      dispatch(showToast('An error occurred while sending email.', 'error'))
    }
  }

  return (
    <Modal closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose} style={ModalStyles} ariaHideApp={false}>
      <div className="flex justify-center items-center">
        <div className="flex flex-col md:w-[600px] w-full md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
          <div className="flex flex-col overflow-hidden mr-[15px] mt-[-20px]">
            <div className="request-contact-container flex flex-col w-full md:pl-[60px] md:pr-[60px] pl-[20px] pr-[20px]">
              <div className="flex flex-col w-full pb-6">
                <div className="h-[1px] bg-[#DDDDDD]"></div>

                <p className="flex items-center h-[81px] text-[18px] font-bold font-secondary text-darkgray mt-11 text-center">
                  We are still reviewing your vendor, we'll get back to you soon
                </p>

                <div className="flex justify-center w-full py-6">
                  <button
                    className="px-7 h-[50px] rounded-full bg-[#011d58] grow-2 w-[200px] font-semibold text-[14px]"
                    type="submit"
                    onClick={handleContactUs}
                  >
                    <p className="text-white">Contact US</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
